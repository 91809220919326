import { useEffect, useState, useRef } from "react";
import "../components/BookCar.scss";
import config from "../config";
import "./BookCar.scss";
import Sedan from "../images/cars-big/Sedan.jpg";
import Hatchback from "../images/cars-big/Hatchback.jpg";
import SUV from "../images/cars-big/SUV.jpg";
import CompactSUV from "../images/cars-big/CompactSUV.jpg";

import {
  faHeart,
  faUser,
  faMotorcycle,
  faIndianRupeeSign,
} from "@fortawesome/free-solid-svg-icons";

function CarWithDriver() {
  // booking car
  const [modal, setModal] = useState(false);
  const [pickUpLocation, setPickUpLocation] = useState("");
  const [dropOffLocation, setDropOffLocation] = useState("");
  const [pickUpDate, setPickUpDate] = useState("");
  const [dropOffDate, setDropOffDate] = useState("");
  const [pickUpTime, setPickUpTime] = useState("");
  const [dropOffTime, setDropOffTime] = useState("");
  const [carType, setCarType] = useState("");

  // modal infos
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [age, setAge] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");

  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [accidentResponsibility, setAccidentResponsibility] = useState(false);
  const [vehicleDamageResponsibility, setVehicleDamageResponsibility] =
    useState(false);
  const [illegalActivityResponsibility, setIllegalActivityResponsibility] =
    useState(false);
  const [bikeImg, setBikeImg] = useState("");

  // taking value of modal inputs
  const handleFirstName = (e) => {
    const value = e.target.value;
    const regex = /^[A-Za-z ]*$/;
    if (value === "" || regex.test(value)) {
      setFirstName(value);
    }
  };

  const handleCar = (e) => {
    setCarType(e.target.value);
  };

  const handleLastName = (e) => {
    const value = e.target.value;
    const regex = /^[A-Za-z ]*$/;
    if (value === "" || regex.test(value)) {
      setLastName(value);
    }
  };

  const handlePhoneNumber = (e) => {
    const value = e.target.value;
    const regex = /^[0-9]*$/;
    if (value === "" || regex.test(value)) {
      setPhoneNumber(value);
    }
  };

  const handleAge = (e) => {
    setAge(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleAddress = (e) => {
    setAddress(e.target.value);
  };

  const handleCity = (e) => {
    const value = e.target.value;
    const regex = /^[A-Za-z ]*$/;
    if (value === "" || regex.test(value)) {
      setCity(value);
    }
  };

  // const handleZip = (e) => {
  //   const value = e.target.value;
  //   const regex = /^[0-9]*$/;
  //   if (value === "" || regex.test(value)) {
  //     setZipCode(value);
  //   }
  // };

  const handlePickUpTime = (e) => {
    setPickUpTime(e.target.value);
  };

  const handleDropOffTime = (e) => {
    setDropOffTime(e.target.value);
  };

  const handleAccidentResponsibility = (e) => {
    setAccidentResponsibility(e.target.checked);
  };

  const handleVehicleDamageResponsibility = (e) => {
    setVehicleDamageResponsibility(e.target.checked);
  };

  const handleIllegalActivityResponsibility = (e) => {
    setIllegalActivityResponsibility(e.target.checked);
  };

  // open modal when all inputs are fulfilled
  const openModal = (e) => {
    setErrorMsg("");
    const doneMsg = document.querySelector(".booking-done");
    doneMsg.style.display = "none";
    e.preventDefault();
    const errorMsg = document.querySelector(".error-message");
    if (
      pickUpLocation === "" ||
      dropOffLocation === "" ||
      pickUpTime === "" ||
      dropOffTime === "" ||
      pickUpDate === "" ||
      dropOffDate === "" ||
      carType === ""
    ) {
      errorMsg.style.display = "flex";
    } else {
      setModal(!modal);
      const modalDiv = document.querySelector(".booking-modal");
      modalDiv.scroll(0, 0);
      errorMsg.style.display = "none";

      //clearing the input fields
      setFirstName("");
      setLastName("");
      setPhoneNumber("");
      setAge("");
      setEmail("");
      setAddress("");
      setCity("");
      setAccidentResponsibility(false);
      setVehicleDamageResponsibility(false);
      setIllegalActivityResponsibility(false);
    }
  };

  // disable page scroll when modal is displayed
  useEffect(() => {
    if (modal === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [modal]);

  const confirmBooking = async (e) => {
    e.preventDefault();
    if (
      firstName === "" ||
      lastName === "" ||
      phoneNumber === "" ||
      age === "" ||
      email === "" ||
      address === "" ||
      city === "" ||
      accidentResponsibility === false ||
      vehicleDamageResponsibility === false ||
      illegalActivityResponsibility === false
    ) {
      alert("All fields are required.");
    } else {
      setModal(!modal);
      const bookingWithDriverReq = {
        firstName,
        lastName,
        phoneNumber,
        age,
        email,
        address,
        city,
        pickUpDate,
        dropOffDate,
        pickUpTime,
        dropOffTime,
        pickUpLocation,
        dropOffLocation,
        carType,
        accidentResponsibility: `${accidentResponsibility}`,
        vehicleDamageResponsibility: `${vehicleDamageResponsibility}`,
        illegalActivityResponsibility: `${illegalActivityResponsibility}`,
      };

      try {
        let url = "";
        if (process.env.NODE_ENV === "development") {
          url = config.apiUrl + "/bookingWithDriver";
        } else {
          url = config.apiUrl + "/bookingWithDriver";
        }
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bookingWithDriverReq),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        if (data.code === "200") {
          setSuccessMsg(
            "Your Booking with driver is confirmed. we will connect you in some time or you can also call on this 9006484700."
          );
        } else {
          setErrorMsg("Network response was not ok.");
          throw new Error("Network response was not ok");
        }
      } catch (error) {
        // setErrorMsg(
        //   "There is an error with your submission. please try again."
        // );
        setErrorMsg(
          "Our system is currently undergoing maintenance. To confirm your booking, please call us at +91 9006484700."
        );
        console.error("Error fetching data:", error);
      } finally {
        setPickUpLocation("");
        setDropOffLocation("");
        setPickUpTime("");
        setDropOffTime("");
        setPickUpDate("");
        setDropOffDate("");
        setCarType("");
        const requiredFieldMsg = document.querySelector(".error-message");
        requiredFieldMsg.style.display = "none";
      }
    }
  };

  const handlePickUpLocation = (e) => {
    setPickUpLocation(e.target.value);
  };

  const handleDropOffLocation = (e) => {
    setDropOffLocation(e.target.value);
  };

  const handlePickUpDate = (e) => {
    setPickUpDate(e.target.value);
  };

  const handleDropOffDate = (e) => {
    setDropOffDate(e.target.value);
  };

  // hide message
  const hideMessage = () => {
    const doneMsg = document.querySelector(".booking-done");
    doneMsg.style.display = "none";
  };

  useEffect(() => {
    const doneMsg = document.querySelector(".booking-done");
    doneMsg.style.display = "none";
  }, []);

  useEffect(() => {
    if (successMsg) {
      const timer = setTimeout(() => {
        setSuccessMsg("");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [successMsg]);

  useEffect(() => {
    if (setErrorMsg) {
      const timer = setTimeout(() => {
        setErrorMsg("");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [errorMsg]);

  return (
    <>
      <section>
        {successMsg ? (
          <div
            className="booking-done"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {successMsg}
          </div>
        ) : errorMsg ? (
          <div
            className="booking-done"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {errorMsg}
          </div>
        ) : null}
        <div
          onClick={openModal}
          className={`modal-overlay ${modal ? "active-modal" : ""}`}
        ></div>

        <form className="box-form">
          <div className="box-form__car-type">
            <label>
              <i className="fa-solid fa-car"></i> &nbsp; Select Your Car Type{" "}
              <b>*</b>
            </label>
            <select
              required
              value={carType}
              onChange={handleCar}
              className="input-box-clour"
            >
              <option>Select your car type</option>
              <option value="Sedan">Sedan</option>
              <option value="Hatchback">Hatchback</option>
              <option value="SUV">SUV</option>
              <option value="Compact SUV">Compact SUV</option>
            </select>
          </div>
          <div className="box-form__car-type">
            <label>
              <i className="fa-solid fa-location-dot"></i> &nbsp; Pick-up
              Location <b>*</b>
            </label>
            <input
              type="text"
              className="test-box input-box-clour"
              maxLength={20}
              placeholder="pick up location"
              value={pickUpLocation}
              onChange={handlePickUpLocation}
            ></input>
          </div>
          <div className="box-form__car-type">
            <label>
              <i className="fa-solid fa-location-dot"></i> &nbsp; Drop-off
              Location <b>*</b>
            </label>
            <input
              type="text"
              className="test-box input-box-clour"
              maxLength={20}
              placeholder="drop off location"
              value={dropOffLocation}
              onChange={handleDropOffLocation}
            ></input>
          </div>
          <div className="box-form__car-time">
            <label htmlFor="picktime" className="date-time-label">
            <i className="fa-regular fa-calendar-days date-time-icon-colour"></i> &nbsp; Pick-up
              Date & Time <b>*</b>
            </label>
            <div>
              <input
                id="picktime"
                value={pickUpDate}
                onChange={handlePickUpDate}
                className="input-box-clour input-field-width"
                type="date"
              />
              <input
                id="pickUptime"
                value={pickUpTime}
                onChange={handlePickUpTime}
                className="input-time input-field-width"
                type="time"
              />
            </div>
          </div>
          <div className="box-form__car-time">
            <label htmlFor="droptime" className="date-time-label">
            <i className="fa-regular fa-calendar-days date-time-icon-colour"></i> &nbsp; Drop-off
              Date & Time <b>*</b>
            </label>
            <div>
              <input
                id="droptime"
                value={dropOffDate}
                onChange={handleDropOffDate}
                className="input-box-clour input-field-width"
                type="date"
              />
              <input
                id="dropUpTime"
                value={dropOffTime}
                onChange={handleDropOffTime}
                className="input-time input-field-width"
                type="time"
              />
            </div>
          </div>
          <button onClick={openModal} type="submit">
            Search
          </button>
        </form>
      </section>

      {/*Normal modal ------------------------------------ */}

      <div className={`booking-modal ${modal ? "active-modal" : ""}`}>
        <div className="booking-modal__title">
          <h2>Book Bike Ride</h2>
          <i onClick={openModal} className="fa-solid fa-xmark"></i>
        </div>
        {/* <div className="booking-modal__car-info">
          <div className="dates-div">
            <div className="booking-modal__car-info__dates">
              <h5>Location & Date</h5>
              <span>
                <i className="fa-solid fa-location-dot"></i>
                <div>
                  <h6>Pick-Up Date & Time</h6>
                  <p>
                    {pickUpDate} /&nbsp;{pickUpTime}
                  </p>
                </div>
              </span>
            </div>

            <div className="booking-modal__car-info__dates">
              <span>
                <i className="fa-solid fa-location-dot"></i>
                <div>
                  <h6>Drop-Off Date & Time</h6>
                  <p>
                    {dropOffDate} /&nbsp;{dropOffTime}
                  </p>
                </div>
              </span>
            </div>

            <div className="booking-modal__car-info__dates">
              <span>
                <i className="fa-solid fa-calendar-days"></i>
                <div>
                  <h6>Pick-Up Location</h6>
                  <p>{pickUpLocation}</p>
                </div>
              </span>
            </div>

            <div className="booking-modal__car-info__dates">
              <span>
                <i className="fa-solid fa-calendar-days"></i>
                <div>
                  <h6>Drop-Off Location</h6>
                  <p>{dropOffLocation}</p>
                </div>
              </span>
            </div>
          </div>
          <div className="booking-modal__car-info__model">
            <h5>
              <span>Bike -</span> {bikeType}
            </h5>
            {imgUrl && <img src={imgUrl} alt="car_img" />}
          </div>
        </div> */}
        {/* personal info */}
        <div className="booking-modal__person-info">
          <h4>Personal Information</h4>
          <form className="info-form">
            <div className="info-form__2col">
              <span>
                <label>
                  First Name <b>*</b>
                </label>
                <input
                  value={firstName}
                  onChange={handleFirstName}
                  type="text"
                  maxLength={20}
                  placeholder="Enter your first name"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Last Name <b>*</b>
                </label>
                <input
                  value={lastName}
                  onChange={handleLastName}
                  type="text"
                  placeholder="Enter your last name"
                  maxLength={20}
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>

              <span>
                <label>
                  Phone Number <b>*</b>
                </label>
                <input
                  value={phoneNumber}
                  onChange={handlePhoneNumber}
                  type="tel"
                  placeholder="Enter your phone number"
                  maxLength={12}
                  minLength={12}
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Age <b>*</b>
                </label>
                <input
                  value={age}
                  onChange={handleAge}
                  type="number"
                  placeholder="Age"
                  maxLength={2}
                  minLength={2}
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>
            </div>

            <div className="info-form__2col">
              <span>
                <label>
                  Email <b>*</b>
                </label>
                <input
                  value={email}
                  onChange={handleEmail}
                  type="email"
                  placeholder="Enter your email address"
                  maxLength={50}
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>
              <span>
                <label>
                  City <b>*</b>
                </label>
                <input
                  value={city}
                  onChange={handleCity}
                  type="text"
                  placeholder="Enter your city"
                  maxLength={20}
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Address <b>*</b>
                </label>
                <input
                  value={address}
                  onChange={handleAddress}
                  type="text"
                  placeholder="Enter your street address"
                  maxLength={50}
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>
            </div>

            <div className="info-form__2col">
              {/* <span>
                <label>
                  Pin Code <b>*</b>
                </label>
                <input
                  value={zipCode}
                  onChange={handleZip}
                  type="text"
                  placeholder="Enter your zip code"
                  maxLength={6}
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span> */}

              {/* <span>
                <label>
                  Aadhaar Upload <b>*</b>
                </label>
                <input
                  type="file"
                  accept=".jpg,.jpeg,.pdf"
                  onChange={onAddhaarFileChange}
                  ref={aadhaarFileInputRef}
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Driving Licence Upload <b>*</b>
                </label>
                <input
                  type="file"
                  accept=".jpg,.jpeg,.pdf"
                  onChange={onDlFileChange}
                  ref={dlFileInputRef}
                ></input>
                <p className="error-modal">This field is required.</p>
              </span> */}
            </div>

            <span className="text-style">
              <p>
                <b>
                  Please read the terms and conditions carefully before booking!
                </b>
              </p>
            </span>

            <span className="info-form__checkbox">
              <input
                checked={accidentResponsibility}
                onChange={handleAccidentResponsibility}
                type="checkbox"
              ></input>
              <p className="error-modal" required>
                If an accident occurs, AgiroCabs employees and the car vendor
                will not be held responsible; you will be solely accountable for
                any consequences.
              </p>
            </span>

            <span className="info-form__checkbox">
              <input
                checked={vehicleDamageResponsibility}
                onChange={handleVehicleDamageResponsibility}
                type="checkbox"
              ></input>
              <p className="error-modal" required>
                If you cause any vehicle damage or accident, you will be
                responsible for covering the full amount, minus any insurance
                claim coverage.
              </p>
            </span>

            <span className="info-form__checkbox">
              <input
                checked={illegalActivityResponsibility}
                onChange={handleIllegalActivityResponsibility}
                type="checkbox"
              ></input>
              <p className="error-modal" required>
                If you are found engaging in any illegal activity inside or
                outside the vehicle, you will be solely responsible for it.
              </p>
            </span>

            <div className="reserve-button">
              <button onClick={confirmBooking}>Confirm Booking</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default CarWithDriver;
