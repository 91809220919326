import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";

function OurPolicy() {
  return (
    <>
      <section className="admin-page">
        <HeroPages name="Our Policy" />
        <div className="container">
          <div className="our-policy-content">
            <h2>Rental Information</h2>
            <p>
              We provide 180 km/day. If you drive more than 180 km in a day, you
              need to pay ₹8/km for the extra distance. The minimum booking
              period is 24 hours.
            </p>

            <h3 className="header-content-style">Important Points</h3>
            <ul>
              <li>
                If an accident occurs, AgiroCabs employees and the car vendor
                will not be held responsible; you will be solely accountable for
                any consequences.
              </li>
              <li>
                If you cause any vehicle damage or accident, you will be
                responsible for covering the full amount, minus any insurance
                claim coverage.
              </li>
              <li>
                If you are found engaging in any illegal activity inside or
                outside the vehicle, you will be solely responsible for it.
              </li>
            </ul>
          </div>
        </div>
        <div className="book-banner">
          <div className="book-banner__overlay"></div>
          <div className="container">
            <div className="text-content">
              <h2>Book Self Drive Car | Car for Weddings | Car with Driver | Rental
              Car for Outings, Parties, and More with AgiroCabs for all your car
              rental needs!</h2>
              <span>
                <i className="fa-solid fa-phone"></i>
                <h3>+91 9006484700</h3>
              </span>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default OurPolicy;
