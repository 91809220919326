import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

function Footer() {
  return (
    <>
      <footer>
        <div className="container">
          <div className="footer-content">
            <ul className="footer-content__2">
              <li>
                <span>CAR</span> Rental
              </li>
              <li>
                AgiroCabs offers a wide range of self-drive Car | Car for Wedding | Car with Driver in
                Patna and Chapra, Bihar, to meet all your driving needs.{" "}
              </li>
            </ul>
            <ul className="footer-content__2">
              <li>CONTACT US</li>
              <li>
                <a href="#home"><i className="fa-solid fa-phone"></i> &nbsp; +91 9006484700</a>
              </li>
              <li>
                <a href="#home"><i className="fa-solid fa-envelope"></i>
                &nbsp; support@agirocabs.com</a>
              </li>
              <li>
                <a href="#home"><i className="fa fa-map-marker fa-lg"></i>
                &nbsp; CIMP, Institutional Area, Mithapur, Patna 800001</a>
              </li>
            </ul>

            <ul className="footer-content__2">
              <li>Working Hours</li>
              <li>Mon - Sat: 24*7</li>
              {/* <li>Mon - Sat: 9:00AM - 9:00PM</li>
              <li>Sat: 9:00AM - 19:00PM</li>
              <li>Sun: Closed</li> */}
            </ul>

            <ul className="footer-content__2">
              <li>Social Network</li>
              <li>
                <a
                  href="https://www.facebook.com/agirocabs"
                  // className="social-link"
                >
                  <FontAwesomeIcon
                    icon={faFacebook}
                    className="facebook-icon"
                  />
                </a>
                <a
                  href="https://www.instagram.com/agirocabs/"
                  // className="instagram-icon"
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    className="instagram-icon"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/uas/login?session_redirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2F30887922%2Fadmin%2F"
                  // className="social-link"
                >
                  <FontAwesomeIcon
                    icon={faLinkedin}
                    className="linkedin-icon "
                  />
                </a>
                <a
                  href="#"
                  // className="social-link"
                >
                  <FontAwesomeIcon icon={faTwitter} className="twitter-icon" />
                </a>
              </li>
              {/* <li>
                <input type="email" placeholder="Enter Email Address"></input>
              </li> */}
              {/* <li>
                <button className="submit-email">Submit</button>
              </li> */}
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
