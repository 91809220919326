import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import { useNavigate } from "react-router-dom";
import "../Pages/SignIn.scss";
import config from "../config";
import { ClipLoader } from "react-spinners";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faUser,
  faMotorcycle,
  faIndianRupeeSign,
  faStore,
} from "@fortawesome/free-solid-svg-icons";

function SignIn() {
  const [errorMsg, setErrorMsg] = useState("");
  const [password, setPassword] = useState("");
  const [userName, setUserName] = useState("");
  const [adminRespData, setAdminRespData] = useState(null);
  const [adminWeddingRespData, setAdminWeddingRespData] = useState(null);
  const [adminWithDriverRespData, setAdminWithDriverRespData] = useState(null);
  const [adminBikeBookingRespData, setAdminBikeBookingRespData] =
    useState(null);
  const [adminVendorRespData, setAdminVendorRespData] = useState(null);
  const [adminFlag, setAdminFlag] = useState(true);
  const [spinnerFlag, setSpinnerFlag] = useState(false);
  const [filters, setFilters] = useState({
    firstName: "",
  });
  const [filteredData, setFilteredData] = useState([]);
  const [filteredWeddingData, setFilteredWeddingData] = useState([]);
  const [filteredWithDriverData, setFilteredWithDriverData] = useState([]);
  const [filteredBikeBookingData, setFilteredBikeBookingData] = useState([]);
  const [userAccessDetails, setUSerAccessDetails] = useState({
    userName: "",
    password: "",
  });
  const [activeTab, setActiveTab] = useState("");

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleUserName = (e) => {
    setUserName(e.target.value);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const navigate = useNavigate();

  const confirmAccess = async (e) => {
    e.preventDefault();
    setErrorMsg("");
    setAdminFlag(false);
    setSpinnerFlag(true);
    try {
      let url = "";
      if (process.env.NODE_ENV === "development") {
        url = config.apiUrl + "/adminAccess";
      } else {
        url = config.apiUrl + "/adminAccess";
      }

      const adminData = {
        userName: userName,
        password: password,
      };

      setUSerAccessDetails({
        userName: userName,
        password: password,
      });

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(adminData),
      });
      const data = await response.json();

      if (data.userInfoRespList) {
        data.userInfoRespList.forEach((element) => {
          if (element.aadhaarFileData) {
            element.aadhaarFileBase64 = convertToBase64(
              element.aadhaarFileData,
              element.aadhaarFileType
            );
          }
          if (element.dlFileData) {
            element.dlFileBase64 = convertToBase64(
              element.dlFileData,
              element.dlFileType
            );
          }
        });
        setAdminRespData(data.userInfoRespList);
      }

      if (data.weddingInfoResponseList) {
        data.weddingInfoResponseList.forEach((element) => {
          if (element.aadhaarFileData) {
            element.aadhaarFileBase64 = convertToBase64(
              element.aadhaarFileData,
              element.aadhaarFileType
            );
          }
        });
        setAdminWeddingRespData(data.weddingInfoResponseList);
      }

      if (data.bookingWithDriverInfo) {
        setAdminWithDriverRespData(data.bookingWithDriverInfo);
      }

      if (data.bikeBookingInfo) {
        setAdminBikeBookingRespData(data.bikeBookingInfo);
      }

      if (data) {
        setAdminFlag(false);
      } else {
        setAdminFlag(true);
        setErrorMsg(
          userName +
            ", the requested data is either unavailable or you don't have the necessary access permissions."
        );
      }
    } catch (error) {
      setAdminFlag(true);
      setErrorMsg("Error in fetching data.");
      console.error("Error in fetching data:", error);
    } finally {
      setUserName("");
      setPassword("");
      setSpinnerFlag(false);
    }
  };

  const updateBookingAcceptReject = async () => {
    setErrorMsg("");
    setAdminFlag(false);
    setSpinnerFlag(true);
    try {
      let url = "";
      if (process.env.NODE_ENV === "development") {
        url = config.apiUrl + "/adminAccess";
      } else {
        url = config.apiUrl + "/adminAccess";
      }

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userAccessDetails),
      });
      const data = await response.json();

      if (data.userInfoRespList) {
        data.userInfoRespList.forEach((element) => {
          if (element.aadhaarFileData) {
            element.aadhaarFileBase64 = convertToBase64(
              element.aadhaarFileData,
              element.aadhaarFileType
            );
          }
          if (element.dlFileData) {
            element.dlFileBase64 = convertToBase64(
              element.dlFileData,
              element.dlFileType
            );
          }
        });
      }

      setAdminRespData(data.userInfoRespList);

      if (data) {
        setAdminFlag(false);
      } else {
        setErrorMsg(userName + " You don't have the access.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setUserName("");
      setPassword("");
      setSpinnerFlag(false);
    }
  };

  const convertToBase64 = (base64Data, fileType) => {
    return `data:${fileType};base64,${base64Data}`;
  };

  const handleDownload = (base64Data, fileName) => {
    const link = document.createElement("a");
    link.href = base64Data;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const acceptRejectHandler = async (userId, bookingAcceptReject) => {
    setSpinnerFlag(true);
    try {
      if (bookingAcceptReject === "NO") {
        let url = "";
        if (process.env.NODE_ENV === "development") {
          url = config.apiUrl + "/bookingAcceptReject";
        } else {
          url = config.apiUrl + "/bookingAcceptReject";
        }

        const bookingAcceptRejectReq = {
          userId: userId,
          bookingAcceptReject: bookingAcceptReject,
        };

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bookingAcceptRejectReq),
        });
        const data = await response.text();
        if (data) {
          await updateBookingAcceptReject();
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setSpinnerFlag(false);
    }
  };

  const deleteHandler = async (userId, deleteSw) => {
    setSpinnerFlag(true);
    try {
      if (deleteSw === "N") {
        let url = "";
        if (process.env.NODE_ENV === "development") {
          url = config.apiUrl + "/bookingAcceptReject";
        } else {
          url = config.apiUrl + "/bookingAcceptReject";
        }

        const bookingAcceptRejectReq = {
          userId: userId,
          deleteSw: deleteSw,
        };

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bookingAcceptRejectReq),
        });
        const data = await response.text();
        if (data) {
          await updateBookingAcceptReject();
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setSpinnerFlag(false);
    }
  };

  useEffect(() => {
    if (adminRespData) {
      const filtered = adminRespData.filter((response) => {
        return (
          !filters.firstName ||
          response.firstName
            .toLowerCase()
            .includes(filters.firstName.toLowerCase())
        );
      });
      setFilteredData(filtered);
    }
  }, [filters, adminRespData]);

  useEffect(() => {
    if (adminWeddingRespData) {
      const filtered = adminWeddingRespData.filter((response) => {
        return (
          !filters.firstName ||
          response.firstName
            .toLowerCase()
            .includes(filters.firstName.toLowerCase())
        );
      });
      setFilteredWeddingData(filtered);
    }
  }, [filters, adminWeddingRespData]);

  useEffect(() => {
    if (adminWithDriverRespData) {
      const filtered = adminWithDriverRespData.filter((response) => {
        return (
          !filters.firstName ||
          response.firstName
            .toLowerCase()
            .includes(filters.firstName.toLowerCase())
        );
      });
      setFilteredWithDriverData(filtered);
    }
  }, [filters, adminWithDriverRespData]);

  useEffect(() => {
    if (adminBikeBookingRespData) {
      const filtered = adminBikeBookingRespData.filter((response) => {
        return (
          !filters.firstName ||
          response.firstName
            .toLowerCase()
            .includes(filters.firstName.toLowerCase())
        );
      });
      setFilteredBikeBookingData(filtered);
    }
  }, [filters, adminBikeBookingRespData]);

  return (
    <>
      <section className="admin-page">
        <HeroPages name="Admin Panel" />
        {adminFlag ? (
          <div className="container">
            {errorMsg ? <div className="error-msg">{errorMsg}</div> : null}

            <div className="admin-div">
              <div className="admin-div__text">
                <h2>
                  Only Admin Can Access This. User Can directly book a car.
                </h2>
              </div>
              <div className="admin-div__form">
                <form>
                  <label>
                    User Name <b>*</b>
                  </label>
                  <input
                    type="text"
                    required
                    value={userName}
                    onChange={handleUserName}
                    placeholder='E.g: "Abc@123"'
                  ></input>
                  <label>
                    Password <b>*</b>
                  </label>
                  <input
                    type="password"
                    required
                    value={password}
                    onChange={handlePassword}
                    placeholder="password"
                  ></input>
                  <button type="submit" onClick={confirmAccess}>
                    <i className="fa-solid fa-envelope-open-text"></i>&nbsp;
                    Admin Login
                  </button>
                </form>
              </div>
            </div>
          </div>
        ) : spinnerFlag ? (
          <div className="spinner-container spinner-style">
            <ClipLoader color="#123abc" spinnerFlag={spinnerFlag} size={50} />
          </div>
        ) : (
          <div className="">
            <div className="tabs">
              <button
                className={`${
                  activeTab === "selfBookDetails" ? "active" : ""
                } tab-signIn-style`}
                onClick={() => handleTabChange("selfBookDetails")}
              >
                Self Drive Car &nbsp; <i className="fa-solid fa-car"></i>
              </button>
              <button
                className={`${
                  activeTab === "weddingBookDetails" ? "active" : ""
                } tab-signIn-style`}
                onClick={() => handleTabChange("weddingBookDetails")}
              >
                Wedding Booking &nbsp;{" "}
                <FontAwesomeIcon icon={faHeart} className="ring-icon" />
              </button>
              <button
                className={`${
                  activeTab === "withDriver" ? "active" : ""
                } tab-signIn-style`}
                onClick={() => handleTabChange("withDriver")}
              >
                Car With Driver &nbsp;{" "}
                <FontAwesomeIcon icon={faUser} className="user-icon" />
              </button>
              <button
                className={`${
                  activeTab === "selfBookBike" ? "active" : ""
                } tab-signIn-style`}
                onClick={() => handleTabChange("selfBookBike")}
              >
                Self Drive Bike &nbsp;{" "}
                <FontAwesomeIcon icon={faMotorcycle} className="car-icon" />
              </button>
              <button
                className={`${
                  activeTab === "vendorDetails" ? "active" : ""
                } tab-signIn-style`}
                onClick={() => handleTabChange("vendorDetails")}
              >
                Vendor Details &nbsp;{" "}
                <FontAwesomeIcon icon={faStore} className="vendor-icon" />
              </button>
            </div>

            {activeTab === "selfBookDetails" ? (
              <div className="table-container">
                <div className="filters-style">
                  <input
                    type="text"
                    name="firstName"
                    placeholder="Filter By Name"
                    value={filters.firstName}
                    onChange={handleFilterChange}
                    className="filter-input-box"
                  />
                </div>
                <table className="responsive-table">
                  <thead>
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Age</th>
                      <th>Phone NO</th>
                      <th>Pick UP Location</th>
                      <th>Drop OFF Location</th>
                      <th>Pick UP Date</th>
                      <th>Pick UP Time</th>
                      <th>Drop OFF Date</th>
                      <th>Drop OFF Time</th>
                      <th>Car Type</th>
                      <th>Create Date & Time</th>
                      <th>Address</th>
                      <th>Aadhaar img</th>
                      <th>DL img</th>
                      <th>Accept/Reject</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData &&
                      filteredData.map((response) => (
                        <tr key={response.userId}>
                          <td data-label="First Name">{response.firstName}</td>
                          <td data-label="Last Name">{response.lastName}</td>
                          <td data-label="Age">{response.age}</td>
                          <td data-label="Phone NO">{response.phoneNumber}</td>
                          <td data-label="Pick UP Location">
                            {response.pickUpLocation}
                          </td>
                          <td data-label="Drop OFF Location">
                            {response.dropOffLocation}
                          </td>
                          <td data-label="Pick UP Date">{response.pickTime}</td>
                          <td data-label="Pick Up Time">
                            {response.pickUpTime}
                          </td>
                          <td data-label="Drop OFF Date">
                            {response.dropTime}
                          </td>
                          <td data-label="Drop OFF Time">
                            {response.dropUpTime}
                          </td>
                          <td data-label="Car Type">{response.carType}</td>
                          <td data-label="Create Date & Time">
                            {response.createDateTime}
                          </td>
                          <td data-label="Address">{response.address}</td>
                          <td data-label="Aadhaar img">
                            {response.aadhaarFileBase64 ? (
                              <img
                                src={response.aadhaarFileBase64}
                                alt="Aadhaar"
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleDownload(
                                    response.aadhaarFileBase64,
                                    "aadhaar_image.jpg"
                                  )
                                }
                              />
                            ) : (
                              "No Aadhaar Image"
                            )}
                          </td>
                          <td data-label="DL img">
                            {response.dlFileBase64 ? (
                              <img
                                src={response.dlFileBase64}
                                alt="Driving License"
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleDownload(
                                    response.dlFileBase64,
                                    "driving_license.jpg"
                                  )
                                }
                              />
                            ) : (
                              "No DL Image"
                            )}
                          </td>
                          <td data-label="Accept/Reject">
                            <button
                              type="submit"
                              className="accept-button"
                              onClick={() =>
                                acceptRejectHandler(
                                  response.userId,
                                  response.bookingAcceptReject
                                )
                              }
                            >
                              {response.bookingAcceptReject}
                            </button>
                          </td>
                          <td data-label="Delete">
                            <button
                              type="submit"
                              className="reject-button"
                              onClick={() =>
                                deleteHandler(
                                  response.userId,
                                  response.deleteSw
                                )
                              }
                            >
                              <i className="fa-solid fa-trash"></i>&nbsp;
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            ) : activeTab === "weddingBookDetails" ? (
              adminWeddingRespData ? (
                <div className="table-container">
                  <div className="filters-style">
                    <input
                      type="text"
                      name="firstName"
                      placeholder="Filter By Name"
                      value={filters.firstName}
                      onChange={handleFilterChange}
                      className="filter-input-box"
                    />
                  </div>
                  <table className="responsive-table">
                    <thead>
                      <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Phone NO</th>
                        <th>Pick UP Location</th>
                        <th>Drop OFF Location</th>
                        <th>Pick UP Date</th>
                        <th>Pick UP Time</th>
                        <th>Drop OFF Date</th>
                        <th>Drop OFF Time</th>
                        <th>Car Type</th>
                        <th>Create Date & Time</th>
                        <th>Address</th>
                        <th>Aadhaar img</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredWeddingData &&
                        filteredWeddingData.map((response) => (
                          <tr key={response.userId}>
                            <td data-label="First Name">
                              {response.firstName}
                            </td>
                            <td data-label="Last Name">{response.lastName}</td>
                            <td data-label="Phone NO">
                              {response.phoneNumber}
                            </td>
                            <td data-label="Pick UP Location">
                              {response.pickUpLocation}
                            </td>
                            <td data-label="Drop OFF Location">
                              {response.dropOffLocation}
                            </td>
                            <td data-label="Pick UP Date">
                              {response.pickUpDate}
                            </td>
                            <td data-label="Pick Up Time">
                              {response.pickUpTime}
                            </td>
                            <td data-label="Drop OFF Date">
                              {response.dropOffDate}
                            </td>
                            <td data-label="Drop OFF Time">
                              {response.dropOffTime}
                            </td>
                            <td data-label="Car Type">{response.carType}</td>
                            <td data-label="Create Date & Time">
                              {response.createDateTime}
                            </td>
                            <td data-label="Address">{response.address}</td>
                            <td data-label="Aadhaar img">
                              {response.aadhaarFileBase64 ? (
                                <img
                                  src={response.aadhaarFileBase64}
                                  alt="Aadhaar"
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleDownload(
                                      response.aadhaarFileBase64,
                                      "aadhaar_image.jpg"
                                    )
                                  }
                                />
                              ) : (
                                "No Aadhaar Image"
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className="no-record-found-style">
                  No Record Found for Wedding
                </p>
              )
            ) : activeTab === "vendorDetails" ? (
              adminVendorRespData ? (
                <div className="table-container">
                  <div className="filters-style">
                    <input
                      type="text"
                      name="firstName"
                      placeholder="Filter By Name"
                      value={filters.firstName}
                      onChange={handleFilterChange}
                      className="filter-input-box"
                    />
                  </div>
                  <table className="responsive-table">
                    <thead>
                      <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Phone NO</th>
                        <th>Pick UP Location</th>
                        <th>Drop OFF Location</th>
                        <th>Pick UP Date</th>
                        <th>Pick UP Time</th>
                        <th>Drop OFF Date</th>
                        <th>Drop OFF Time</th>
                        <th>Create Date & Time</th>
                        <th>Address</th>
                        <th>Aadhaar img</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredWeddingData &&
                        filteredWeddingData.map((response) => (
                          <tr key={response.userId}>
                            <td data-label="First Name">
                              {response.firstName}
                            </td>
                            <td data-label="Last Name">{response.lastName}</td>
                            <td data-label="Phone NO">
                              {response.phoneNumber}
                            </td>
                            <td data-label="Pick UP Location">
                              {response.pickUpLocation}
                            </td>
                            <td data-label="Drop OFF Location">
                              {response.dropOffLocation}
                            </td>
                            <td data-label="Pick UP Date">
                              {response.pickUpDate}
                            </td>
                            <td data-label="Pick Up Time">
                              {response.pickUpTime}
                            </td>
                            <td data-label="Drop OFF Date">
                              {response.dropOffDate}
                            </td>
                            <td data-label="Drop OFF Time">
                              {response.dropOffTime}
                            </td>
                            <td data-label="Create Date & Time">
                              {response.createDateTime}
                            </td>
                            <td data-label="Address">{response.address}</td>
                            <td data-label="Aadhaar img">
                              {response.aadhaarFileBase64 ? (
                                <img
                                  src={response.aadhaarFileBase64}
                                  alt="Aadhaar"
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleDownload(
                                      response.aadhaarFileBase64,
                                      "aadhaar_image.jpg"
                                    )
                                  }
                                />
                              ) : (
                                "No Aadhaar Image"
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className="no-record-found-style">
                  No Record Found for Vendor
                </p>
              )
            ) : activeTab === "withDriver" ? (
              adminWithDriverRespData ? (
                <div className="table-container">
                  <div className="filters-style">
                    <input
                      type="text"
                      name="firstName"
                      placeholder="Filter By Name"
                      value={filters.firstName}
                      onChange={handleFilterChange}
                      className="filter-input-box"
                    />
                  </div>
                  <table className="responsive-table">
                    <thead>
                      <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Phone NO</th>
                        <th>Pick UP Location</th>
                        <th>Drop OFF Location</th>
                        <th>Pick UP Date</th>
                        <th>Pick UP Time</th>
                        <th>Drop OFF Date</th>
                        <th>Drop OFF Time</th>
                        <th>Car Type</th>
                        <th>Create Date & Time</th>
                        <th>Address</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredWithDriverData &&
                        filteredWithDriverData.map((response) => (
                          <tr key={response.userId}>
                            <td data-label="First Name">
                              {response.firstName}
                            </td>
                            <td data-label="Last Name">{response.lastName}</td>
                            <td data-label="Phone NO">
                              {response.phoneNumber}
                            </td>
                            <td data-label="Pick UP Location">
                              {response.pickUpLocation}
                            </td>
                            <td data-label="Drop OFF Location">
                              {response.dropOffLocation}
                            </td>
                            <td data-label="Pick UP Date">
                              {response.pickUpDate}
                            </td>
                            <td data-label="Pick Up Time">
                              {response.pickUpTime}
                            </td>
                            <td data-label="Drop OFF Date">
                              {response.dropOffDate}
                            </td>
                            <td data-label="Drop OFF Time">
                              {response.dropOffTime}
                            </td>
                            <td data-label="Car Type">{response.carType}</td>
                            <td data-label="Create Date & Time">
                              {response.createDateTime}
                            </td>
                            <td data-label="Address">{response.address}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className="no-record-found-style">
                  No Record Found for Booking With Driver
                </p>
              )
            ) : activeTab === "selfBookBike" ? (
              adminBikeBookingRespData ? (
                <div className="table-container">
                  <div className="filters-style">
                    <input
                      type="text"
                      name="firstName"
                      placeholder="Filter By Name"
                      value={filters.firstName}
                      onChange={handleFilterChange}
                      className="filter-input-box"
                    />
                  </div>
                  <table className="responsive-table">
                    <thead>
                      <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Phone NO</th>
                        <th>Pick UP Location</th>
                        <th>Drop OFF Location</th>
                        <th>Pick UP Date</th>
                        <th>Pick UP Time</th>
                        <th>Drop OFF Date</th>
                        <th>Drop OFF Time</th>
                        <th>Bike Type</th>
                        <th>Create Date & Time</th>
                        <th>Address</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredBikeBookingData &&
                        filteredBikeBookingData.map((response) => (
                          <tr key={response.userId}>
                            <td data-label="First Name">
                              {response.firstName}
                            </td>
                            <td data-label="Last Name">{response.lastName}</td>
                            <td data-label="Phone NO">
                              {response.phoneNumber}
                            </td>
                            <td data-label="Pick UP Location">
                              {response.pickUpLocation}
                            </td>
                            <td data-label="Drop OFF Location">
                              {response.dropOffLocation}
                            </td>
                            <td data-label="Pick UP Date">
                              {response.pickUpDate}
                            </td>
                            <td data-label="Pick Up Time">
                              {response.pickUpTime}
                            </td>
                            <td data-label="Drop OFF Date">
                              {response.dropOffDate}
                            </td>
                            <td data-label="Drop OFF Time">
                              {response.dropOffTime}
                            </td>
                            <td data-label="Bike Type">{response.bikeType}</td>
                            <td data-label="Create Date & Time">
                              {response.createDateTime}
                            </td>
                            <td data-label="Address">{response.address}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className="no-record-found-style">
                  No Record Found for Self Drive Bike
                </p>
              )
            ) : null}
          </div>
        )}

        <div className="book-banner">
          <div className="book-banner__overlay"></div>
          <div className="container">
            <div className="text-content">
              <h2>Book Self Drive Car | Car for Weddings | Car with Driver | Rental
              Car for Outings, Parties, and More with AgiroCabs for all your car
              rental needs!</h2>
              <span>
                <i className="fa-solid fa-phone"></i>
                <h3>+91 9006484700</h3>
              </span>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default SignIn;
