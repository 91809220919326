import { useEffect, useState, useRef } from "react";
import "../components/BookCar.scss";
import config from "../config";
import "./BookCar.scss";
import Sedan from "../images/cars-big/Sedan.jpg";
import Hatchback from "../images/cars-big/Hatchback.jpg";
import SUV from "../images/cars-big/SUV.jpg";
import CompactSUV from "../images/cars-big/CompactSUV.jpg";
import Swift from "../images/cars-big/swift.jpg";
import SwiftDzire from "../images/cars-big/dzire.jpg";
import Baleno from "../images/cars-big/baleno.jpg";
import Ecosport from "../images/cars-big/EcoSport.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faUser,
  faMotorcycle,
  faIndianRupeeSign,
} from "@fortawesome/free-solid-svg-icons";
import SelfDriveBike from "./SelfDriveBike";
import CarWithDriver from "./CarWithDriver";

function BookCar() {
  const [modal, setModal] = useState(false);
  const [weddingModal, setWeddingModal] = useState(false); //  class - active-modal

  // booking car
  const [pickUp, setPickUp] = useState("");
  const [carType, setCarType] = useState("");
  const [dropOff, setDropOff] = useState("");
  const [pickTime, setPickTime] = useState("");
  const [dropTime, setDropTime] = useState("");
  const [carImg, setCarImg] = useState("");
  const [carImgNew, setCarImgNew] = useState("");
  const [pickUpTime, setPickUpTime] = useState("");
  const [dropUpTime, setDropUpTime] = useState("");

  // modal infos
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [age, setAge] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipCode] = useState("");

  const [errorMsg, setErrorMsg] = useState("");
  const [accidentResponsibility, setAccidentResponsibility] = useState(false);
  const [vehicleDamageResponsibility, setVehicleDamageResponsibility] =
    useState(false);
  const [illegalActivityResponsibility, setIllegalActivityResponsibility] =
    useState(false);
  const [aadhaarFile, setAadhaarFile] = useState(null);
  const [message, setMessage] = useState("");
  const aadhaarFileInputRef = useRef(null);
  const [dlFile, setDlFile] = useState(null);
  const dlFileInputRef = useRef(null);
  const [activeTab, setActiveTab] = useState("selfBook");
  const [amount, setAmount] = useState(0);
  const [successMsg, setSuccessMsg] = useState("");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setCarType("");
    setPickUp("");
    setDropOff("");
    setPickTime("");
    setDropTime("");
    setPickUpTime("");
    setDropUpTime("");
    setAmount("");
  };

  // taking value of modal inputs
  const handleName = (e) => {
    const value = e.target.value;
    const regex = /^[A-Za-z ]*$/;
    if (value === "" || regex.test(value)) {
      setName(value);
    }
  };

  const handleLastName = (e) => {
    const value = e.target.value;
    const regex = /^[A-Za-z ]*$/;
    if (value === "" || regex.test(value)) {
      setLastName(value);
    }
  };

  const handlePhone = (e) => {
    const value = e.target.value;
    const regex = /^[0-9]*$/;
    if (value === "" || regex.test(value)) {
      setPhone(value);
    }
  };

  const handleAge = (e) => {
    setAge(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleAddress = (e) => {
    setAddress(e.target.value);
  };

  const handleCity = (e) => {
    const value = e.target.value;
    const regex = /^[A-Za-z ]*$/;
    if (value === "" || regex.test(value)) {
      setCity(value);
    }
  };

  const handleZip = (e) => {
    const value = e.target.value;
    const regex = /^[0-9]*$/;
    if (value === "" || regex.test(value)) {
      setZipCode(value);
    }
  };

  const handlePickUpTime = (e) => {
    setPickUpTime(e.target.value);
  };

  const handleDropUpTime = (e) => {
    setDropUpTime(e.target.value);
  };

  const handleAccidentResponsibility = (e) => {
    setAccidentResponsibility(e.target.checked);
  };

  const handleVehicleDamageResponsibility = (e) => {
    setVehicleDamageResponsibility(e.target.checked);
  };

  const handleIllegalActivityResponsibility = (e) => {
    setIllegalActivityResponsibility(e.target.checked);
  };

  const onAddhaarFileChange = (e) => {
    setAadhaarFile(e.target.files[0]);
  };

  const onDlFileChange = (e) => {
    setDlFile(e.target.files[0]);
  };

  // open modal when all inputs are fulfilled
  const openModal = (e) => {
    setErrorMsg("");
    const doneMsg = document.querySelector(".booking-done");
    doneMsg.style.display = "none";
    e.preventDefault();
    const errorMsg = document.querySelector(".error-message");
    if (
      pickUp === "" ||
      dropOff === "" ||
      pickTime === "" ||
      dropTime === "" ||
      carType === "" ||
      pickUpTime === "" ||
      dropUpTime === ""
    ) {
      errorMsg.style.display = "flex";
    } else {
      setModal(!modal);
      const modalDiv = document.querySelector(".booking-modal");
      modalDiv.scroll(0, 0);
      errorMsg.style.display = "none";

      //clearing the input fields
      setCarImg("");
      setName("");
      setLastName("");
      setPhone("");
      setAge("");
      setEmail("");
      setAddress("");
      setCity("");
      setZipCode("");
      setAccidentResponsibility(false);
      setVehicleDamageResponsibility(false);
      setIllegalActivityResponsibility(false);
    }
  };

  const openWeddingModal = (e) => {
    setErrorMsg("");
    const doneMsg = document.querySelector(".booking-done");
    doneMsg.style.display = "none";
    e.preventDefault();
    const errorMsg = document.querySelector(".error-message");
    if (
      pickUp === "" ||
      dropOff === "" ||
      pickTime === "" ||
      dropTime === "" ||
      carType === "" ||
      pickUpTime === "" ||
      dropUpTime === ""
    ) {
      errorMsg.style.display = "flex";
    } else {
      setWeddingModal(!weddingModal);
      const modalDiv = document.querySelector(".booking-modal");
      modalDiv.scroll(0, 0);
      errorMsg.style.display = "none";

      //clearing the input fields
      setName("");
      setLastName("");
      setPhone("");
      setAddress("");
      setCity("");
      setZipCode("");
      setAccidentResponsibility(false);
      setVehicleDamageResponsibility(false);
      setIllegalActivityResponsibility(false);
    }
  };

  // disable page scroll when modal is displayed
  useEffect(() => {
    if (modal === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [modal]);

  useEffect(() => {
    if (successMsg) {
      const timer = setTimeout(() => {
        setSuccessMsg("");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [successMsg]);

  useEffect(() => {
    if (setErrorMsg) {
      const timer = setTimeout(() => {
        setErrorMsg("");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [errorMsg]);

  useEffect(() => {
    if (weddingModal === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [weddingModal]);

  const confirmBooking = async (e) => {
    e.preventDefault();
    if (
      name === "" ||
      lastName === "" ||
      phone === "" ||
      age === "" ||
      email === "" ||
      address === "" ||
      city === "" ||
      zipcode === "" ||
      pickTime === "" ||
      dropTime === "" ||
      pickUp === "" ||
      dropOff === "" ||
      accidentResponsibility === false ||
      vehicleDamageResponsibility === false ||
      illegalActivityResponsibility === false ||
      aadhaarFile === null ||
      dlFile === null
    ) {
      alert("All fields are required.");
    } else {
      const dlFileSizeInMB = dlFile.size / 1024 / 1024;
      const aadhaarFileSizeInMB = aadhaarFile.size / 1024 / 1024;
      if (dlFileSizeInMB > 5 || aadhaarFileSizeInMB > 5) {
        alert("Aadhaar or DL size can not be more than 5 MB");
      } else {
        setModal(!modal);
        const userInfoReq = {
          firstName: `${name}`,
          lastName: `${lastName}`,
          phoneNumber: `${phone}`,
          age: `${age}`,
          email: `${email}`,
          address: `${address}`,
          city: `${city}`,
          zipCode: `${zipcode}`,
          pickTime: `${pickTime}`,
          dropTime: `${dropTime}`,
          pickUpTime: `${pickUpTime}`,
          dropUpTime: `${dropUpTime}`,
          pickUpLocation: `${pickUp}`,
          dropOffLocation: `${dropOff}`,
          carType: `${carType}`,
          accidentResponsibility: `${accidentResponsibility}`,
          vehicleDamageResponsibility: `${vehicleDamageResponsibility}`,
          illegalActivityResponsibility: `${illegalActivityResponsibility}`,
        };

        const formData = new FormData();
        formData.append("aadhaarFile", aadhaarFile);
        formData.append("dlFile", dlFile);
        formData.append("userInfoReq", JSON.stringify(userInfoReq));

        try {
          let url = "";
          if (process.env.NODE_ENV === "development") {
            url = config.apiUrl + "/carBooking";
          } else {
            url = config.apiUrl + "/carBooking";
          }
          const response = await fetch(url, {
            method: "POST",
            body: formData,
          });
          const data = await response.json();
          if (data.errorCode === "200") {
            setSuccessMsg(
              "Your Car Booking is confirmed. we will connect you in some time or you can also call on this 9006484700."
            );
          } else {
            setErrorMsg("Network response was not ok.");
            throw new Error("Network response was not ok");
          }
        } catch (error) {
          // setErrorMsg(
          //   "There is an error with your submission. please try again."
          // );
          setErrorMsg(
            "Our system is currently undergoing maintenance. To confirm your booking, please call us at +91 9006484700."
          );
          console.error("Error fetching data:", error);
        } finally {
          setCarType("");
          setPickUp("");
          setDropOff("");
          setPickTime("");
          setDropTime("");
          setPickUpTime("");
          setDropUpTime("");
          const requiredFieldMsg = document.querySelector(".error-message");
          requiredFieldMsg.style.display = "none";
          setAadhaarFile(null);
          setDlFile(null);
          if (aadhaarFileInputRef.current || dlFileInputRef.current) {
            aadhaarFileInputRef.current.value = ""; // Reset the aadhaar file input field
            dlFileInputRef.current.value = ""; // Reset the dl file input field
          }
        }
      }
    }
  };

  const confirmWeddingBooking = async (e) => {
    e.preventDefault();
    if (
      name === "" ||
      lastName === "" ||
      phone === "" ||
      address === "" ||
      city === "" ||
      zipcode === "" ||
      pickTime === "" ||
      dropTime === "" ||
      pickUp === "" ||
      dropOff === "" ||
      accidentResponsibility === false ||
      vehicleDamageResponsibility === false ||
      illegalActivityResponsibility === false ||
      aadhaarFile === null
    ) {
      alert("All fields are required.");
    } else {
      const aadhaarFileSizeInMB = aadhaarFile.size / 1024 / 1024;
      if (aadhaarFileSizeInMB > 5) {
        alert("Aadhaar or DL size can not be more than 5 MB");
      } else {
        setWeddingModal(!weddingModal);
        const weddingInfoReq = {
          firstName: `${name}`,
          lastName: `${lastName}`,
          phoneNumber: `${phone}`,
          address: `${address}`,
          city: `${city}`,
          zipCode: `${zipcode}`,
          pickUpDate: `${pickTime}`,
          dropOffDate: `${dropTime}`,
          pickUpTime: `${pickUpTime}`,
          dropOffTime: `${dropUpTime}`,
          pickUpLocation: `${pickUp}`,
          amount,
          dropOffLocation: `${dropOff}`,
          carType: `${carType}`,
          accidentResponsibility: `${accidentResponsibility}`,
          vehicleDamageResponsibility: `${vehicleDamageResponsibility}`,
          illegalActivityResponsibility: `${illegalActivityResponsibility}`,
        };

        const formData = new FormData();
        formData.append("aadhaarFile", aadhaarFile);
        formData.append("weddingInfoReq", JSON.stringify(weddingInfoReq));

        try {
          let url = "";
          if (process.env.NODE_ENV === "development") {
            url = config.apiUrl + "/weddingBooking";
          } else {
            url = config.apiUrl + "/weddingBooking";
          }
          const response = await fetch(url, {
            method: "POST",
            body: formData,
          });
          const data = await response.json();
          if (data.code === "200") {
            setSuccessMsg(
              "Your Car Booking is confirmed. we will connect you in some time or you can also call on this 9006484700."
            );
          } else {
            setErrorMsg("Network response was not ok.");
            throw new Error("Network response was not ok");
          }
        } catch (error) {
          // setErrorMsg(
          //   "There is an error with your submission. please try again."
          // );
          setErrorMsg(
            "Our system is currently undergoing maintenance. To confirm your booking, please call us at +91 9006484700."
          );
          console.error("Error fetching data:", error);
        } finally {
          setCarType("");
          setPickUp("");
          setDropOff("");
          setPickTime("");
          setDropTime("");
          setPickUpTime("");
          setDropUpTime("");
          setAmount("");
          const requiredFieldMsg = document.querySelector(".error-message");
          requiredFieldMsg.style.display = "none";
          setAadhaarFile(null);
          if (aadhaarFileInputRef.current) {
            aadhaarFileInputRef.current.value = ""; // Reset the aadhaar file input field
          }
        }
      }
    }
  };

  // taking value of booking inputs
  const handleCar = (e) => {
    setCarType(e.target.value);
    setCarImg(e.target.value);
    let carName = e.target.value;
    if (
      carName === "Swift" ||
      carName === "Baleno" ||
      carName === "Swift Dzire"
    ) {
      setAmount(3500);
    } else {
      setAmount(4000);
    }
  };

  const handleCarNew = (e) => {
    setCarType(e.target.value);
    setCarImgNew(e.target.value);
  };

  const handlePick = (e) => {
    setPickUp(e.target.value);
  };

  const handleDrop = (e) => {
    setDropOff(e.target.value);
  };

  const handlePickTime = (e) => {
    setPickTime(e.target.value);
  };

  const handleDropTime = (e) => {
    setDropTime(e.target.value);
  };

  // based on value name show car img
  let weddingImgUrl;
  switch (carImg) {
    case "Swift":
      weddingImgUrl = Swift;
      break;
    case "Swift Dzire":
      weddingImgUrl = SwiftDzire;
      break;
    case "Baleno":
      weddingImgUrl = Baleno;
      break;
    case "Ecosport":
      weddingImgUrl = Ecosport;
      break;
    default:
      weddingImgUrl = "";
  }

  let imgUrl;
  switch (carImgNew) {
    case "Sedan":
      imgUrl = Sedan;
      break;
    case "Hatchback":
      imgUrl = Hatchback;
      break;
    case "SUV":
      imgUrl = SUV;
      break;
    case "Compact SUV":
      imgUrl = CompactSUV;
      break;
    default:
      imgUrl = "";
  }

  // hide message
  const hideMessage = () => {
    const doneMsg = document.querySelector(".booking-done");
    doneMsg.style.display = "none";
  };

  useEffect(() => {
    const doneMsg = document.querySelector(".booking-done");
    doneMsg.style.display = "none";
  }, []);

  return (
    <>
      <section id="booking-section" className="book-section">
        {/* overlay */}
        <div
          onClick={openModal}
          className={`modal-overlay ${modal ? "active-modal" : ""}`}
        ></div>
        <div
          onClick={openWeddingModal}
          className={`modal-overlay ${weddingModal ? "active-modal" : ""}`}
        ></div>
        {/* <div className="container our-policy-content rental-info-style">
          <h2>Rental Information</h2>
          <p>
            We provide 120 km/day. If you drive more than 120 km in a day, you
            need to pay ₹9/km for the extra distance. The minimum booking period
            is 24 hours.
          </p>
        </div> */}
        <div className="container">
          <div className="tabs">
            <button
              className={`${
                activeTab === "selfBook" ? "active" : ""
              } tab-style`}
              onClick={() => handleTabChange("selfBook")}
            >
              Self Drive Car &nbsp; <i className="fa-solid fa-car"></i>
            </button>
            <button
              className={`${
                activeTab === "selfBookBike" ? "active" : ""
              } tab-style`}
              onClick={() => handleTabChange("selfBookBike")}
            >
              Self Drive Bike &nbsp;{" "}
              <FontAwesomeIcon icon={faMotorcycle} className="car-icon" />
            </button>
            <button
              className={`${
                activeTab === "withDriver" ? "active" : ""
              } tab-style`}
              onClick={() => handleTabChange("withDriver")}
            >
              Car With Driver &nbsp;{" "}
              <FontAwesomeIcon icon={faUser} className="user-icon" />
            </button>
            <button
              className={`${
                activeTab === "weddingBook" ? "active" : ""
              } tab-style`}
              onClick={() => handleTabChange("weddingBook")}
            >
              Wedding Booking &nbsp;{" "}
              <FontAwesomeIcon icon={faHeart} className="ring-icon" />
            </button>
          </div>

          <div className="book-content">
            <div className="book-content__box">
              {successMsg ? (
                <div
                  className="booking-done"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {successMsg}
                </div>
              ) : errorMsg ? (
                <div
                  className="booking-done"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {errorMsg}
                </div>
              ) : null}
              <h2>
                {activeTab === "selfBook" ? "Book Self Drive Car" : ""}
                {activeTab === "selfBookBike" ? "Book Self Drive Bike" : ""}
                {activeTab === "withDriver" ? "Book Car With Driver" : ""}
                {activeTab === "weddingBook" ? "Book car for wedding" : ""}
              </h2>
              <p className="error-message" style={{ justifyContent: "center" }}>
                All fields required!
              </p>

              <p
                className="booking-done"
                style={{ display: "flex", justifyContent: "center" }}
              >
                Your car booking is confirmed. we will connect you in some time
                or you can also call on this 9006484700.{"  "}
                <i
                  onClick={hideMessage}
                  className="fa-solid fa-xmark cross-icon"
                ></i>
              </p>
              {activeTab === "selfBookBike" ? <SelfDriveBike /> : ""}
              {activeTab === "withDriver" ? <CarWithDriver /> : ""}
              {activeTab === "selfBook" ? (
                <form className="box-form">
                  <div className="box-form__car-type">
                    <label>
                      <i className="fa-solid fa-car"></i> &nbsp; Select Your Car
                      Type <b>*</b>
                    </label>
                    <select
                      required
                      value={carType}
                      onChange={handleCarNew}
                      className="input-box-clour"
                    >
                      <option>Select your car type</option>
                      <option value="Sedan">Sedan</option>
                      <option value="Hatchback">Hatchback</option>
                      <option value="SUV">SUV</option>
                      <option value="Compact SUV">Compact SUV</option>
                    </select>
                  </div>
                  <div className="box-form__car-type">
                    <label>
                      <i className="fa-solid fa-location-dot"></i> &nbsp;
                      Pick-up Location <b>*</b>
                    </label>
                    <input
                      type="text"
                      className="test-box input-box-clour"
                      maxLength={20}
                      placeholder="pick up location"
                      value={pickUp}
                      onChange={handlePick}
                    ></input>
                  </div>
                  <div className="box-form__car-type">
                    <label>
                      <i className="fa-solid fa-location-dot"></i> &nbsp;
                      Drop-off Location <b>*</b>
                    </label>
                    <input
                      type="text"
                      className="test-box input-box-clour"
                      maxLength={20}
                      placeholder="drop off location"
                      value={dropOff}
                      onChange={handleDrop}
                    ></input>
                  </div>
                  <div className="box-form__car-time">
                    <label htmlFor="picktime" className="date-time-label">
                    <i className="fa-regular fa-calendar-days date-time-icon-colour"></i> &nbsp;
                      Pick-up Date & Time <b>*</b>
                    </label>
                    <div>
                      <input
                        id="picktime"
                        value={pickTime}
                        onChange={handlePickTime}
                        className="input-box-clour input-field-width"
                        type="date"
                      />
                      <input
                        id="pickUptime"
                        value={pickUpTime}
                        onChange={handlePickUpTime}
                        className="input-time input-field-width"
                        type="time"
                      />
                    </div>
                  </div>
                  <div className="box-form__car-time">
                    <label htmlFor="droptime" className="date-time-label">
                      <i className="fa-regular fa-calendar-days date-time-icon-colour"></i> &nbsp;
                      Drop-off Date & Time <b>*</b>
                    </label>
                    <div>
                      <input
                        id="droptime"
                        value={dropTime}
                        onChange={handleDropTime}
                        className="input-box-clour input-field-width"
                        type="date"
                      />
                      <input
                        id="dropUpTime"
                        value={dropUpTime}
                        onChange={handleDropUpTime}
                        className="input-time input-field-width"
                        type="time"
                      />
                    </div>
                  </div>
                  <button onClick={openModal} type="submit">
                    Search
                  </button>
                </form>
              ) : (
                ""
              )}
              {/* Wedding season booking is coming soon! */}
              {activeTab === "weddingBook" ? (
                <form className="box-form">
                  <div className="box-form__car-type">
                    <label>
                      <i className="fa-solid fa-car"></i> &nbsp; Select Your Car
                      Type <b>*</b>
                    </label>
                    <select
                      required
                      value={carType}
                      onChange={handleCar}
                      className="input-box-clour"
                    >
                      <option>Select your car type</option>
                      <option value="Swift">Swift</option>
                      <option value="Swift Dzire">Swift Dzire</option>
                      <option value="Baleno">Baleno</option>
                      <option value="Ecosport">Ecosport</option>
                    </select>
                  </div>
                  <div className="box-form__car-type">
                    <label>
                      <FontAwesomeIcon
                        icon={faIndianRupeeSign}
                        className="rupee-icon-style"
                      />{" "}
                      &nbsp; Amount <b>*</b>
                    </label>
                    <input
                      type="text"
                      className="test-box input-box-clour"
                      maxLength={20}
                      placeholder="Amount"
                      value={amount}
                      disabled
                    ></input>
                  </div>
                  <div className="box-form__car-type">
                    <label>
                      <i className="fa-solid fa-location-dot"></i> &nbsp;
                      Pick-up Location <b>*</b>
                    </label>
                    <input
                      type="text"
                      className="test-box input-box-clour"
                      maxLength={20}
                      placeholder="pick up location"
                      value={pickUp}
                      onChange={handlePick}
                    ></input>
                  </div>
                  <div className="box-form__car-type">
                    <label>
                      <i className="fa-solid fa-location-dot"></i> &nbsp;
                      Drop-off Location <b>*</b>
                    </label>
                    <input
                      type="text"
                      className="test-box input-box-clour"
                      maxLength={20}
                      placeholder="drop off location"
                      value={dropOff}
                      onChange={handleDrop}
                    ></input>
                  </div>
                  <div className="box-form__car-time">
                    <label htmlFor="picktime" className="date-time-label">
                    <i className="fa-regular fa-calendar-days date-time-icon-colour"></i> &nbsp;
                      Pick-up Date & Time <b>*</b>
                    </label>
                    <div>
                      <input
                        id="picktime"
                        value={pickTime}
                        onChange={handlePickTime}
                        className="input-box-clour input-field-width"
                        type="date"
                      />
                      <input
                        id="pickUptime"
                        value={pickUpTime}
                        onChange={handlePickUpTime}
                        className="input-time input-field-width"
                        type="time"
                      />
                    </div>
                  </div>
                  <div className="box-form__car-time">
                    <label htmlFor="droptime" className="date-time-label">
                    <i className="fa-regular fa-calendar-days date-time-icon-colour"></i> &nbsp;
                      Drop-off Date & Time <b>*</b>
                    </label>
                    <div>
                      <input
                        id="droptime"
                        value={dropTime}
                        onChange={handleDropTime}
                        className="input-box-clour input-field-width"
                        type="date"
                      />
                      <input
                        id="dropUpTime"
                        value={dropUpTime}
                        onChange={handleDropUpTime}
                        className="input-time input-field-width"
                        type="time"
                      />
                    </div>
                  </div>
                  <button onClick={openWeddingModal} type="submit">
                    Search
                  </button>
                </form>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>

      {/*Normal modal ------------------------------------ */}

      <div className={`booking-modal ${modal ? "active-modal" : ""}`}>
        <div className="booking-modal__title">
          <h2>Book Car Ride</h2>
          <i onClick={openModal} className="fa-solid fa-xmark"></i>
        </div>
        <div className="booking-modal__car-info">
          <div className="dates-div">
            <div className="booking-modal__car-info__dates">
              <h5>Location & Date</h5>
              <span>
                <i className="fa-solid fa-location-dot"></i>
                <div>
                  <h6>Pick-Up Date & Time</h6>
                  <p>
                    {pickTime} /&nbsp;{pickUpTime}
                  </p>
                </div>
              </span>
            </div>

            <div className="booking-modal__car-info__dates">
              <span>
                <i className="fa-solid fa-location-dot"></i>
                <div>
                  <h6>Drop-Off Date & Time</h6>
                  <p>
                    {dropTime} /&nbsp;{dropUpTime}
                  </p>
                </div>
              </span>
            </div>

            <div className="booking-modal__car-info__dates">
              <span>
                <i className="fa-solid fa-calendar-days"></i>
                <div>
                  <h6>Pick-Up Location</h6>
                  <p>{pickUp}</p>
                </div>
              </span>
            </div>

            <div className="booking-modal__car-info__dates">
              <span>
                <i className="fa-solid fa-calendar-days"></i>
                <div>
                  <h6>Drop-Off Location</h6>
                  <p>{dropOff}</p>
                </div>
              </span>
            </div>
          </div>
          <div className="booking-modal__car-info__model">
            <h5>
              <span>Car -</span> {carType}
            </h5>
            {imgUrl && <img src={imgUrl} alt="car_img" />}
          </div>
        </div>
        {/* personal info */}
        <div className="booking-modal__person-info">
          <h4>Personal Information</h4>
          <form className="info-form">
            <div className="info-form__2col">
              <span>
                <label>
                  First Name <b>*</b>
                </label>
                <input
                  value={name}
                  onChange={handleName}
                  type="text"
                  maxLength={20}
                  placeholder="Enter your first name"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Last Name <b>*</b>
                </label>
                <input
                  value={lastName}
                  onChange={handleLastName}
                  type="text"
                  placeholder="Enter your last name"
                  maxLength={20}
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>

              <span>
                <label>
                  Phone Number <b>*</b>
                </label>
                <input
                  value={phone}
                  onChange={handlePhone}
                  type="tel"
                  placeholder="Enter your phone number"
                  maxLength={12}
                  minLength={12}
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Age <b>*</b>
                </label>
                <input
                  value={age}
                  onChange={handleAge}
                  type="number"
                  placeholder="Age"
                  maxLength={2}
                  minLength={2}
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>
            </div>

            <div className="info-form__1col">
              <span>
                <label>
                  Email <b>*</b>
                </label>
                <input
                  value={email}
                  onChange={handleEmail}
                  type="email"
                  placeholder="Enter your email address"
                  maxLength={50}
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Address <b>*</b>
                </label>
                <input
                  value={address}
                  onChange={handleAddress}
                  type="text"
                  placeholder="Enter your street address"
                  maxLength={50}
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>
            </div>

            <div className="info-form__2col">
              <span>
                <label>
                  City <b>*</b>
                </label>
                <input
                  value={city}
                  onChange={handleCity}
                  type="text"
                  placeholder="Enter your city"
                  maxLength={20}
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Pin Code <b>*</b>
                </label>
                <input
                  value={zipcode}
                  onChange={handleZip}
                  type="text"
                  placeholder="Enter your zip code"
                  maxLength={6}
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>

              <span>
                <label>
                  Aadhaar Upload <b>*</b>
                </label>
                <input
                  type="file"
                  accept=".jpg,.jpeg,.pdf"
                  onChange={onAddhaarFileChange}
                  ref={aadhaarFileInputRef}
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Driving Licence Upload <b>*</b>
                </label>
                <input
                  type="file"
                  accept=".jpg,.jpeg,.pdf"
                  onChange={onDlFileChange}
                  ref={dlFileInputRef}
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>
            </div>

            <span className="text-style">
              <p>
                <b>
                  Please read the terms and conditions carefully before booking!
                </b>
              </p>
            </span>

            <span className="info-form__checkbox">
              <input
                checked={accidentResponsibility}
                onChange={handleAccidentResponsibility}
                type="checkbox"
              ></input>
              <p className="error-modal" required>
                If an accident occurs, AgiroCabs employees and the car vendor
                will not be held responsible; you will be solely accountable for
                any consequences.
              </p>
            </span>

            <span className="info-form__checkbox">
              <input
                checked={vehicleDamageResponsibility}
                onChange={handleVehicleDamageResponsibility}
                type="checkbox"
              ></input>
              <p className="error-modal" required>
                If you cause any vehicle damage or accident, you will be
                responsible for covering the full amount, minus any insurance
                claim coverage.
              </p>
            </span>

            <span className="info-form__checkbox">
              <input
                checked={illegalActivityResponsibility}
                onChange={handleIllegalActivityResponsibility}
                type="checkbox"
              ></input>
              <p className="error-modal" required>
                If you are found engaging in any illegal activity inside or
                outside the vehicle, you will be solely responsible for it.
              </p>
            </span>

            <div className="reserve-button">
              <button onClick={confirmBooking}>Confirm Booking</button>
            </div>
          </form>
        </div>
      </div>

      {/* wedding modal ------------------------------------ */}

      <div className={`booking-modal ${weddingModal ? "active-modal" : ""}`}>
        <div className="booking-modal__title">
          <h2>Book Wedding Ride</h2>
          <i onClick={openWeddingModal} className="fa-solid fa-xmark"></i>
        </div>
        <div className="booking-modal__car-info">
          <div className="dates-div">
            <div className="booking-modal__car-info__dates">
              <h5>Location & Date</h5>
              <span>
                <i className="fa-solid fa-location-dot"></i>
                <div>
                  <h6>Pick-Up Date & Time</h6>
                  <p>
                    {pickTime} /&nbsp;{pickUpTime}
                  </p>
                </div>
              </span>
            </div>

            <div className="booking-modal__car-info__dates">
              <span>
                <i className="fa-solid fa-location-dot"></i>
                <div>
                  <h6>Drop-Off Date & Time</h6>
                  <p>
                    {dropTime} /&nbsp;{dropUpTime}
                  </p>
                </div>
              </span>
            </div>

            <div className="booking-modal__car-info__dates">
              <span>
                <i className="fa-solid fa-calendar-days"></i>
                <div>
                  <h6>Pick-Up Location</h6>
                  <p>{pickUp}</p>
                </div>
              </span>
            </div>

            <div className="booking-modal__car-info__dates">
              <span>
                <i className="fa-solid fa-calendar-days"></i>
                <div>
                  <h6>Drop-Off Location</h6>
                  <p>{dropOff}</p>
                </div>
              </span>
            </div>
          </div>
          <div className="booking-modal__car-info__model">
            <h5>
              <span>Car -</span> {carType}
            </h5>
            {weddingImgUrl && <img src={weddingImgUrl} alt="car_img" />}
          </div>
        </div>
        {/* personal info */}
        <div className="booking-modal__person-info">
          <h4>Personal Information</h4>
          <form className="info-form">
            <div className="info-form__2col">
              <span>
                <label>
                  First Name <b>*</b>
                </label>
                <input
                  value={name}
                  onChange={handleName}
                  type="text"
                  maxLength={20}
                  placeholder="Enter your first name"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Last Name <b>*</b>
                </label>
                <input
                  value={lastName}
                  onChange={handleLastName}
                  type="text"
                  placeholder="Enter your last name"
                  maxLength={20}
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>

              <span>
                <label>
                  Phone Number <b>*</b>
                </label>
                <input
                  value={phone}
                  onChange={handlePhone}
                  type="tel"
                  placeholder="Enter your phone number"
                  maxLength={12}
                  minLength={12}
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Address <b>*</b>
                </label>
                <input
                  value={address}
                  onChange={handleAddress}
                  type="text"
                  placeholder="Enter your street address"
                  maxLength={50}
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>
            </div>
            <div className="info-form__2col">
              <span>
                <label>
                  City <b>*</b>
                </label>
                <input
                  value={city}
                  onChange={handleCity}
                  type="text"
                  placeholder="Enter your city"
                  maxLength={20}
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Zip Code <b>*</b>
                </label>
                <input
                  value={zipcode}
                  onChange={handleZip}
                  type="text"
                  placeholder="Enter your zip code"
                  maxLength={6}
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>

              <span>
                <label>
                  Aadhaar Upload <b>*</b>
                </label>
                <input
                  type="file"
                  accept=".jpg,.jpeg,.pdf"
                  onChange={onAddhaarFileChange}
                  ref={aadhaarFileInputRef}
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>
            </div>

            <span className="text-style">
              <p>
                <b>
                  Please read the terms and conditions carefully before booking!
                </b>
              </p>
            </span>

            <span className="info-form__checkbox">
              <input
                checked={accidentResponsibility}
                onChange={handleAccidentResponsibility}
                type="checkbox"
              ></input>
              <p className="error-modal" required>
                If an accident occurs, AgiroCabs employees and the car vendor
                will not be held responsible; you will be solely accountable for
                any consequences.
              </p>
            </span>

            <span className="info-form__checkbox">
              <input
                checked={vehicleDamageResponsibility}
                onChange={handleVehicleDamageResponsibility}
                type="checkbox"
              ></input>
              <p className="error-modal" required>
                If you cause any vehicle damage or accident, you will be
                responsible for covering the full amount, minus any insurance
                claim coverage.
              </p>
            </span>

            <span className="info-form__checkbox">
              <input
                checked={illegalActivityResponsibility}
                onChange={handleIllegalActivityResponsibility}
                type="checkbox"
              ></input>
              <p className="error-modal" required>
                If you are found engaging in any illegal activity inside or
                outside the vehicle, you will be solely responsible for it.
              </p>
            </span>

            <div className="reserve-button">
              <button onClick={confirmWeddingBooking}>Confirm Booking</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default BookCar;
