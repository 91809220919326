const devConfig = {
    apiUrl: "http://localhost:2509/agiro",
};

const prodConfig = {
    apiUrl: "https://agirocabs.com/agiro",
};

const config = process.env.NODE_ENV === 'development' ? devConfig : prodConfig;

export default config;